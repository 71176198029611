import type {CSSProperties, ReactElement} from 'react';
import React from 'react';
import {escapeHTML} from '@Utils/string.util';
import type {UserVideoVOResponse, USER_VIDEO_SOURCE} from '@Libraries/user-video-library';
import styles from './grid-user-video-item.module.scss';
import type {GridMediaItemStorage} from '../grid-media-item';
import {GridMediaItem} from '../grid-media-item';
import type {GridItemProps} from '../grid-item';
import {GRID_ITEM_TYPE} from '../grid-item';
import VideoPreview from '@Components/video-preview-new/video-preview';
import {isMobile} from 'react-device-detect';

export interface GridUserVideoItemStorage extends GridMediaItemStorage {
  type: GRID_ITEM_TYPE.USER_VIDEO;
  hashedFilename: string;
  extension: string;
  uploaderName: string;
  uploaderId: number;
  duration: number;
  frameRate: number;
  source: USER_VIDEO_SOURCE;
  hasTransparency: boolean;
  gridItemWidth?: number;
  isHidden?: boolean;
}

interface GridUserVideoItemProps extends GridItemProps {
  imgThumbSrc: string;
  videoThumbSrc: string;
  uploaderName: string;
  duration: number;
  gridItemWidth?: number;
  isHidden?: boolean;
}

export const getGridUserVideoItemStorageForUserVideoVO = (userVideoVO: UserVideoVOResponse): GridUserVideoItemStorage => {
  return {
    type: GRID_ITEM_TYPE.USER_VIDEO,
    id: userVideoVO.filename,
    hashedFilename: userVideoVO.filename,
    extension: userVideoVO.ext,
    uploaderName: userVideoVO.uploaderName,
    uploaderId: parseInt(userVideoVO.uploaderId, 10),
    hasTransparency: userVideoVO.hasTransparency,
    source: userVideoVO.src,
    duration: userVideoVO.duration,
    frameRate: userVideoVO.frameRate,
    isHidden: false,
    width: userVideoVO.width,
    height: userVideoVO.height,
  };
};

export function GridUserVideoItem({uploaderName = '', ...props}: GridUserVideoItemProps): ReactElement | null {
  if (props.isHidden) {
    return null;
  }

  const inlineStyles: CSSProperties = props.style ?? {};
  if (props.gridItemWidth) {
    inlineStyles.width = props.gridItemWidth;
  }

  return (
    <GridMediaItem
      {...props}
      containerClassName={`${props.containerClassName} ${isMobile ? styles.mobileVideoItem : ''}`}
      style={inlineStyles}
      className={`${props.className ?? ''} ${styles.gridItem}`}
      title={uploaderName ? `${window.i18next.t('pmwjs_added_by')} ${escapeHTML(uploaderName)}` : ''}
      paddedThumb={false}
      thumb={<VideoPreview isSmallItem={props.isSmallItem} imgPlaceHolderSrc={props.imgThumbSrc} videoSrc={props.videoThumbSrc} duration={props.duration} showUHDIcon={false} />}
    />
  );
}
