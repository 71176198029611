import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {v4 as uuidv4} from 'uuid';
import type {ShowMessageGrowlProps} from './message-growl.types';
import {DEFAULT_MESSAGE_TIMOUT_DURATION, GROWL_TYPE} from './message-growl.types';

export interface MessageGrowlProps {
  text: string;
  show: boolean;
  interval: number;
  type: GROWL_TYPE;
  key: string;
  showCloseIcon?: boolean;
  icon?: string;
  dangerouslySetInnerHTML?: boolean;
}

const initialState: MessageGrowlProps = {
  text: '',
  show: false,
  interval: 0,
  type: GROWL_TYPE.SUCCESS,
  key: '',
  showCloseIcon: true,
  icon: '',
  dangerouslySetInnerHTML: false,
};

const messageGrowlSlice = createSlice({
  name: 'messageGrowl',
  initialState,
  reducers: {
    updateMessageGrowl: (state, action: PayloadAction<ShowMessageGrowlProps>) => {
      state.text = action.payload.text;
      state.key = action.payload.key ?? uuidv4();
      state.interval = action.payload.interval ?? DEFAULT_MESSAGE_TIMOUT_DURATION;
      state.type = action.payload.type ?? GROWL_TYPE.SUCCESS;
      state.show = true;
      state.showCloseIcon = action.payload.showCloseIcon ?? true;
      state.icon = action.payload.icon ?? '';
      state.dangerouslySetInnerHTML = action.payload.dangerouslySetInnerHTML ?? false;
    },
    hideMessageGrowl: (state, action: PayloadAction<string>) => {
      if (action.payload === state.key) {
        state.show = false;
      }
    },
  },
});

export const {updateMessageGrowl, hideMessageGrowl} = messageGrowlSlice.actions;
export const messageGrowlReducer = messageGrowlSlice.reducer;
