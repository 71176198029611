import type {EventsPrefillMorphData, EventsPrefillMorphResponse} from '@Components/event-wizard/event-wizard-image-video/event-wizard-image-video.types';
import {MediaTypes} from '@Components/event-wizard/event-wizard-image-video/event-wizard-image-video.types';
import {capitalizeFirstLetterOfAllWords} from '@Utils/string.util';
import {openUserMediaModal} from '@Modals/user-media-modal';
import {UploadsMediaType} from '@Panels/user-media-panel/user-media-panel.types';
import type {ImageData} from '@Libraries/add-media-library';
import {repoImageScreenURL} from '@Libraries/graphic-item-image-library';
import {onEventWizardCoverPhotoUpdate, onEventWizardIsCoverPhotoPreviewLoading} from '@Components/event-wizard/event-wizard-slice';
import {togglePreviewCoverPhotoLoading, updatePreviewCoverPhoto} from '@Components/event-wizard/preview/preview.helper';
import {EmbeddedEditorAction, openDesignInEmbeddedEditor} from '@Libraries/embedded-editor';
import {getPosterPreviewURL, pollForPosterPreviewChanges} from '@Libraries/poster-library';
import type {EventType} from '@Components/event-wizard/type-dropdown/type-dropdown.types';
import type {TextItemObject} from '@PosterWhiteboard/items/text-item/text-item.types';
import type {PosterLoadObject} from '@PosterWhiteboard/poster/poster.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {setPosterOverlayLoaderVisibility} from '@Components/poster-editor/poster-editor-reducer';
import {MorphType, PosterModeType} from '@PosterWhiteboard/poster/poster-mode.class';
import {noop} from '@Utils/general.util';
import {GalleryThemeURI} from '@Components/design-gallery/design-gallery.types';
import {getUserPremiumLevel} from '@Libraries/user.library';
import {USER_PREMIUM_LEVELS} from '@Utils/user.util';

export const shouldUpsellUserForSemanticTagging = (): boolean => {
  return getUserPremiumLevel() === USER_PREMIUM_LEVELS.PAYG;
};

export const getEventWizardMediaContainerTitle = (mediaType: MediaTypes): string => {
  let title = '';

  if (mediaType === MediaTypes.TEMPLATES) {
    title = window.i18next.t('pmwjs_templates');
  }

  if (mediaType === MediaTypes.MY_DESIGNS) {
    title = window.i18next.t('pmwjs_my_designs');
  }

  if (mediaType === MediaTypes.UPLOAD) {
    title = window.i18next.t('pmwjs_uploads');
  }
  return capitalizeFirstLetterOfAllWords(title);
};

export const openUploadSelector = (): void => {
  openUserMediaModal({
    onAddSelectedCTAText: window.i18next.t('pmwjs_upload_image'),
    maxSelectionNumber: 1,
    defaultTab: UploadsMediaType.IMAGE,
    mediaTypesToShow: [UploadsMediaType.IMAGE],
    uploadAcceptedMimetype: 'image/*',
    onAddSelected: onSelectedFromUpload,
    hideRecordingOptions: true,
  });
};

export const openDesignSelector = (onSelected: () => void): void => {
  window.PMW.util.require(typeof window.PMW.openDesignSelectorModal === 'undefined', 'designSelector', true, () => {
    window.PMW.setLoading(null);
    window.PMW.openDesignSelectorModal(window.i18next.t('pmwjs_select_a_design'), (id: string): void => {
      onSelectedFromMyDesignSelector(id);
      onSelected();
    });
  });
};

export const openGalleryTemplateSelector = (selectedEventType: EventType | null, onSelected: () => void, opts?: EventsPrefillMorphData, isPremium = false): void => {
  const galleryDesignClickedEvent = 'gallery-design-clicked';
  const eventTypeUrl = selectedEventType !== null ? `eventPosterTemplates/${selectedEventType.hashedID}` : GalleryThemeURI.EVENTS;
  window.addEventListener(galleryDesignClickedEvent, onSelectedFromGalleryTemplateSelector.bind(null, opts, onSelected, isPremium) as EventListener);
  window.PMW.openGalleryDialog('', true, eventTypeUrl, !shouldUpsellUserForSemanticTagging(), false);
};

export const onRemoveMedia = (): void => {
  updateMedia(undefined, undefined);
};

export const openEmbeddedEditor = (hid: string, uploadedMediaType: MediaTypes, opts?: EventsPrefillMorphData, isPremium = false): void => {
  const getParams = uploadedMediaType === MediaTypes.TEMPLATES && isPremium ? {...opts, morphType: MorphType.EVENTS_PREFILL} : {...opts};
  const mode = getEmbeddedEditorMode(uploadedMediaType, isPremium, opts);
  const currentTime = new Date();
  openDesignInEmbeddedEditor(
    hid,
    noop,
    (newHID: string) => {
      onEmbeddedEditorClosed(newHID, uploadedMediaType, currentTime);
    },
    noop,
    mode,
    mode === EmbeddedEditorAction.MORPH ? window.i18next.t('pmwjs_done') : undefined,
    {getParams}
  );
};

export const isEmbeddedMorphModeActive = (): boolean => {
  return window.PMW.isEmbedded() && window.PMW.redux.store.getState().posterEditor.posterObject?.mode.details.type === PosterModeType.MORPH;
};

export const handleEventsPreFillMorphingForPoster = async (posterObject: PosterLoadObject, morphData: EventsPrefillMorphData): Promise<PosterLoadObject> => {
  const pageHashid = posterObject.pages.pageOrder[0];
  const {items} = posterObject.pages.pagesHashMap[pageHashid];
  const mapping: Record<string, string> = {};

  items.itemsOrder.forEach((itemId): void => {
    if (items.itemsHashMap[itemId].gitype === ITEM_TYPE.TEXT || items.itemsHashMap[itemId].gitype === ITEM_TYPE.FANCY_TEXT) {
      mapping[itemId] = (items.itemsHashMap[itemId] as TextItemObject).text;
    }
  });

  window.PMW.redux.store.dispatch(setPosterOverlayLoaderVisibility(true));

  if (Object.keys(mapping).length > 0) {
    const response = await morphTextItemsForEventDetails(mapping, morphData);
    for (const graphicItemKey of Object.keys(response.textContentChanges)) {
      if (response.textContentChanges[graphicItemKey].text) {
        items.itemsHashMap[graphicItemKey] = {...items.itemsHashMap[graphicItemKey], ...response.textContentChanges[graphicItemKey]};
      }
    }
  }

  window.PMW.redux.store.dispatch(setPosterOverlayLoaderVisibility(false));

  return posterObject;
};

const updateMedia = (url: string | undefined, uploadedMediaType: MediaTypes | undefined, selectedTemplateHID: string | undefined = undefined): void => {
  window.PMW.redux.store.dispatch(
    onEventWizardCoverPhotoUpdate({
      uploadedUrl: url,
      mediaType: uploadedMediaType,
      selectedTemplateHID,
    })
  );
  void updatePreviewCoverPhoto(url);
};

const onSelectedFromGalleryTemplateSelector = (opts: EventsPrefillMorphData | undefined, onSelected: () => void, isPremium = false, event: CustomEvent<{id: string}>): void => {
  openEmbeddedEditor(event.detail.id, MediaTypes.TEMPLATES, opts, isPremium);
  onSelected();
  window.PMW.closeGalleryDialog(window.location.href, false);
};

const onSelectedFromMyDesignSelector = (hid: string): void => {
  updateMedia(getPosterPreviewURL(hid), MediaTypes.MY_DESIGNS, hid);
};

const getEmbeddedEditorMode = (uploadedMediaType: MediaTypes, isPremium: boolean, opts?: EventsPrefillMorphData): EmbeddedEditorAction => {
  switch (uploadedMediaType) {
    case MediaTypes.MY_DESIGNS:
      return EmbeddedEditorAction.EDIT;
    case MediaTypes.TEMPLATES:
      return isPremium && opts && Object.keys(opts).length > 0 ? EmbeddedEditorAction.MORPH : EmbeddedEditorAction.COPY;
    default:
      throw new Error(`Unhandled case for given media type: ${uploadedMediaType}`);
  }
};

const morphTextItemsForEventDetails = async (itemKeyToTextMapping: Record<string, string>, eventDetails: EventsPrefillMorphData): Promise<EventsPrefillMorphResponse> => {
  return (await window.PMW.readLocal('posterdesignmorph/morphTextForEventDetails', {
    mapping: itemKeyToTextMapping,
    details: eventDetails,
  })) as Promise<EventsPrefillMorphResponse>;
};

const onEmbeddedEditorClosed = (hid: string, uploadedMediaType: MediaTypes | undefined, timeEditorOpened: Date): void => {
  window.PMW.redux.store.dispatch(onEventWizardIsCoverPhotoPreviewLoading(true));
  togglePreviewCoverPhotoLoading(true);

  void hasDesignBeenUpdatedSinceOpeningEditor(hid, timeEditorOpened)
    .then((content) => {
      const hasDesignBeenUpdatedSinceOpeningEditor = content.hasBeenUpdated as boolean;

      if (hasDesignBeenUpdatedSinceOpeningEditor) {
        beginPollingForPreviewChange(hid, uploadedMediaType);
      } else {
        updateMedia(getPosterPreviewURL(hid), uploadedMediaType, hid);
        window.PMW.redux.store.dispatch(onEventWizardIsCoverPhotoPreviewLoading(false));
      }
    })
    .catch((e: unknown) => {
      updateMedia(undefined, undefined, undefined);
      window.PMW.redux.store.dispatch(onEventWizardIsCoverPhotoPreviewLoading(false));
      console.log(e); // selfCodeReviewJibran: error state
    });
};

const onSelectedFromUpload = (images: ImageData[], currentUploadIndex?: number): void => {
  if (currentUploadIndex && currentUploadIndex > 1) {
    return;
  }
  const url = repoImageScreenURL(images[0].hashedFilename, images[0].extension);
  updateMedia(url, MediaTypes.UPLOAD, undefined);
};

const beginPollingForPreviewChange = (hid: string, uploadedMediaType: MediaTypes | undefined) => {
  pollForPosterPreviewChanges(hid)
    .promise.then((): void => {
      updateMedia(getPosterPreviewURL(hid), uploadedMediaType, hid);
    })
    .catch((e: unknown) => {
      updateMedia(undefined, undefined, undefined);
      console.log(e); // selfCodeReviewJibran: error state
    })
    .finally((): void => {
      window.PMW.redux.store.dispatch(onEventWizardIsCoverPhotoPreviewLoading(false));
    });
};

const hasDesignBeenUpdatedSinceOpeningEditor = async (hid: string, timeEditorOpened: Date): Promise<any> => {
  return await window.PMW.writeLocal('event/hasDesignBeenUpdatedSinceOpeningEditor', {
    poster_hid: hid,
    time_editor_opened_ms: timeEditorOpened.getTime(),
  });
};
